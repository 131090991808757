/**
 * Video Modal
 * @description : Swiper for the related show cards etc..
 */

(function () {

    const videoModalEl = document.querySelector('.js-video-modal');

    if (videoModalEl) {

        const closeButtonEl = videoModalEl.querySelector('.js-video-modal__close');
        closeButtonEl.addEventListener('click', () => {
            videoModalEl.toggleAttribute('hidden', true);
            setTimeout(() => {
                iframeEl.src = 'about:blank';
            }, 300);
        });

        const iframeEl = videoModalEl.querySelector('.js-video-modal__iframe');
        const iframeSrcTemplate = iframeEl.getAttribute('data-src');

        const infoBarEl = videoModalEl.querySelector('.js-video-modal__info-bar');
        const headingBarEl = videoModalEl.querySelector('.js-video-modal__heading-bar');

        const titleEl = videoModalEl.querySelector('.js-video-modal__title');
        const titleLabelEl = videoModalEl.querySelector('.js-video-modal__title-label');
        const captionEl = videoModalEl.querySelector('.js-video-modal__caption');

        const triggerEls = Array.from(document.querySelectorAll('.js-video-modal__trigger'));
        triggerEls.forEach(triggerEl => {

            const videoId = triggerEl.getAttribute('data-video-id');
            const title = triggerEl.getAttribute('data-title');
            const titleLabel = triggerEl.getAttribute('data-title-label');
            const caption = triggerEl.getAttribute('data-caption');

            triggerEl.addEventListener('click', () => {
                iframeEl.src = iframeSrcTemplate.replace('[ID]', videoId);

                titleEl.textContent = title;
                titleLabelEl.textContent = titleLabel;
                captionEl.textContent = caption;

                titleEl.toggleAttribute('hidden', !title);
                titleLabelEl.toggleAttribute('hidden', !titleLabel);
                captionEl.toggleAttribute('hidden', !caption);

                infoBarEl.toggleAttribute('hidden', !title && !titleLabel && !caption);
                headingBarEl.toggleAttribute('hidden', !title && !titleLabel);

                videoModalEl.toggleAttribute('hidden', false);
            });
        });

    }

})();